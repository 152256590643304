<template>
    <div>
        <div>
            <b-button variant="primary" @click="$router.go(-1)" size="sm">
                <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                />
                <span class="align-middle">BACK</span>
            </b-button>
        </div>
        <div class="topics-details">
            <feather-icon 
                icon="UserCheckIcon"
                size="35"
            />
            <h1 class="mt-2">Company Registration</h1> 
            <span>
                Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
            </span>
        </div>
        
        
        
        <b-row>
            <b-col md="6">
                <b-card class="banner-detail-card mt-3">
                    <h2>UK company registration</h2>
                    <span>(Discounted prices)</span>
                    <div class="d-flex mb-1 mt-4">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="d-flex  mb-1">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="d-flex mb-1">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="d-flex">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="price-details mt-4">
                        <h1 class="mt-2">$500 - $1500</h1> 
                        <h3 class="mt-3">Additional Services</h3>
                    </div>
                    <div class="d-flex justify-content-between mb-1 marketing-price">
                        <span>1 Day Promotion</span>
                        <span>$250</span>
                    </div>
                    <div class="d-flex justify-content-between mb-1 marketing-price">
                        <span>10 Day Promotion</span>
                        <span>$250</span>
                    </div>
                    <div class="d-flex justify-content-between mb-1 marketing-price">
                        <span>1 Month Promotion</span>
                        <span>$250</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>1 Month Promotion</span>
                        <span>$250</span>
                    </div>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card class="banner-detail-card mt-3">
                    <h2>USA company registration</h2>
                    <span>(Kentucky State)</span>
                    <div class="d-flex mb-1 mt-4">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="d-flex  mb-1">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="d-flex mb-1">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="d-flex">
                        <feather-icon 
                            icon="CheckCircleIcon"
                            size="15"
                            class="mr-50"
                        />
                        <span>Old family photos undoubtedly inspire a flood of memories.</span>
                    </div>
                    <div class="price-details mt-4">
                        <h1 class="mt-2">$500 - $1500</h1> 
                        <h3 class="mt-3">Additional Services</h3>
                    </div>
                    <div class="d-flex justify-content-between mb-1 marketing-price">
                        <span>1 Day Promotion</span>
                        <span>$250</span>
                    </div>
                    <div class="d-flex justify-content-between mb-1 marketing-price">
                        <span>10 Day Promotion</span>
                        <span>$250</span>
                    </div>
                    <div class="d-flex justify-content-between mb-1 marketing-price">
                        <span>1 Month Promotion</span>
                        <span>$250</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>1 Month Promotion</span>
                        <span>$250</span>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
//   BImg,
  BButton
} from "bootstrap-vue";

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BCard
    }
    
}
</script>

<style>
    .topics-details {
        text-align: center;
        padding: 1px 200px;
    }
    .discount-text {
        margin-top: 5px;
    }
    @media (max-width: 1024px) {
        .topics-details {
            padding: 0px !important;
        }
    }
    .banner-detail-card {
        background: #070859 !important;
        padding: 50px 5px;
        border-radius: 1px !important;
    }
    .banner-detail-card-right {
        background: #393AF8 !important;
        padding: 50px 5px;
        border-radius: 1px !important;
    }
    .price-details {
        text-align: center;
        font-weight: bolder;
    }
    .marketing-price {
        border-bottom: 1px solid white;
    }
</style>